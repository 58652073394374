.App {
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  font-family: Frank;
}
h1,h2,h3,h3,p{margin: 0;}
@font-face {
  font-family: Pixel;
  src: url(./assets/Minecrafter.Reg.ttf);
}
@font-face {
  font-family: Frank;
  src: url(./assets/LibreFranklin-VariableFont_wght.ttf);
}
.Header{
  width: 100%;
  aspect-ratio: 2.5;
  background-image: url(./assets/1.png);
  background-size: cover;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1vw 0 3vw 0;
}
.HeaderContents{
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.HeaderContents img:first-child {
  animation: bounceIn 1s ease-out, floatAndRotate1 4s ease-in-out 1s infinite;
}

.HeaderContents img:last-child {
  animation: bounceIn 1.5s ease-out, floatAndRotate2 5s ease-in-out 2s infinite;
}

@keyframes bounceIn {
  0% {
    transform: translateY(-100vh) scale(0.5);
  }
  60% {
    transform: translateY(20px) scale(1.05);
    opacity: 1;
  }
  80% {
    transform: translateY(-10px) scale(0.95);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes floatAndRotate1 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-5px) rotate(-1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@keyframes floatAndRotate2 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-7px) rotate(1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.SMIcon {
  width: 5.208vw;
  aspect-ratio: 1;
  background-color: #260b18;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  transition: all 150ms linear;
}
.SMContainer{
  display: flex;
  flex-direction: row;
  gap: 1vw;
}
.SMIcon:hover {
  cursor: pointer;
  background-color: #68555e;
  scale: 110%;
  rotate: 2deg;
}
.XLogo {
  background-image: url(./assets/Xlogo.png);
}

.TelegramLogo {
  background-image: url(./assets/Telegramlogo.png);
}
.Seperator{
  width: 110%;
  height: 4vw;
  rotate: -2deg;
  margin-left: -5%;
  margin-top: -2vw;
  background-color: #260b18;
  border: #68555e 0.5vw solid;
}
.Ticker{
  text-wrap: nowrap;
  color: #fff;
  font-size: 3.125vw;
  text-align: left;
  animation: tickerAnimation 6000ms linear 0s infinite;
}
@keyframes tickerAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
    /* Move to the left end */
  }
}
.Tokenomics{
  width: 100%;
  aspect-ratio: 2.1;
  padding: 4vw 0 1vw 0;
  margin-top: -2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;align-items: center;
  background-image: url('./assets/2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000;
  border-bottom: #000 0.5vw solid;
}
.TitleText{
  font-family: Pixel;
  font-size: 5.208vw;
  
}
.TokenTitle{
  text-shadow: #b57b10 0 0.25vw 0,#b57b10 0.25vw 0.25vw 0,#b57b10 0.25vw 0 0,#b57b10 -0.25vw 0 0,#b57b10 -0.25vw 0.25vw 0,
  #b57b10 0 -0.25vw 0,#b57b10 0.25vw -0.25vw 0,#b57b10 -0.25vw -0.25vw 0,
  #000000 0 0.75vw 0;
  color: #ffffff;
}
.GuideTitle{
  text-shadow: #0c659d 0 0.25vw 0,#0c659d 0.25vw 0.25vw 0,#0c659d 0.25vw 0 0,#0c659d -0.25vw 0 0,#0c659d -0.25vw 0.25vw 0,
               #0c659d 0 -0.25vw 0,#0c659d 0.25vw -0.25vw 0,#0c659d -0.25vw -0.25vw 0,
               #000000 0 0.75vw 0;
  color: #ffffff;
}
.SocialsTitle{
  text-shadow: #278d26 0 0.25vw 0,#278d26 0.25vw 0.25vw 0,#278d26 0.25vw 0 0,#278d26 -0.25vw 0 0,#278d26 -0.25vw 0.25vw 0,
  #278d26 0 -0.25vw 0,#278d26 0.25vw -0.25vw 0,#278d26 -0.25vw -0.25vw 0,
  #000000 0 0.75vw 0;
  color: #ffffff;

}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.TitleText {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.TitleText.slide-in-left {
  animation: slideInLeft 0.8s forwards;
}

.TitleText.slide-in-right {
  animation: slideInRight 0.8s forwards;
}
.TokenomicsContent{
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 1vw;
  margin-right: 52.5%;
}
.TokenomicsData{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  aspect-ratio: 4;
  background-color: #260b18;
  border: #68555e 0.3vw solid;
  color: #fff;
  text-align: center;
}
.TokenomicsData h1{
  text-transform: uppercase;
  font-size: 3.472vw;
}
.TokenomicsData h3{
  font-size: 1.389vw;
}
.BuyBtn{
  color: #000;
  background-color: #1aff0d;
  border: #000 0.4vw solid;
  display: flex;flex-direction: column;
  justify-content: space-around;
  text-transform: uppercase;
  border-radius: 1vw;
  font-weight: 800;
  font-size:3.472vw;
  width: 25vw;
  aspect-ratio: 4.5;
  transition: all 100ms linear;
}
.BuyBtn:hover{
  cursor: pointer;
  background-color: #93ff8e;
  scale: 1.1;
}
.Guide{
  width: 100%;
  aspect-ratio: 2.1;
  padding: 1vw 0 1vw 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-color: #000;
  background-image: url(./assets/3.png), url(./assets/herecomesthesundoodoodoododo.png),linear-gradient(45deg, #14ecff,#9af7ff);
  background-size: 55%,contain,100%;
  background-position: bottom left,bottom left,center;
  border-bottom: #000 0.5vw solid;
}
.GuideDataContainer{
  padding: 1vw 0;
  width: 40%;
  margin-left: 43%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.GuideData{
  width: 96%;
  aspect-ratio: 4;
  padding: 2%;
  background-image: linear-gradient(135deg, hsla(188, 9%, 25%, 0.7),hsla(190, 14%, 30%, 0.5));
  backdrop-filter: blur(0.521vw);
  text-align: left;
  border-radius: 1vw;
  border: #fff 0.2vw solid;
  color: #fff;
  transition: all 100ms ease-in-out;
}
.GuideData:hover{
  scale: 1.05;
}
.GuideData h3{
  font-size: 2.5vw;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: #0c659d 0.25vw 0.25vw 0;
}
.GuideData p{
  font-size: 1.25vw;
  font-weight: 500
}
.PhantomLink{
  color: #fff;
  font-weight: 900;
}
.PhantomLink:hover{
  color: #0c659d;
}
.AnimationContainer{
  width: 100%;
  aspect-ratio: 1.7777;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./assets/animation.gif);
  border-bottom: #000 0.5vw solid;
}
.SocialsContainer{
  width: 100%;
  aspect-ratio: 2.8;
  padding: 1vw 0 1vw 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, #7fe17d,#40c93d);
}
.Footer{
  margin-top: 3vw;
  font-size: 1vw;
}
.imgContainer{
  width: 120%;
  display: flex;
  flex-direction: row;
  margin-top: 2vw;
  align-items: center;
  justify-content: center;
}
@keyframes subtleRotate1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate3 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.imgHolder {
  width: 25vw;
  aspect-ratio: 1;
  background-color: #000;
  border: #fff 1vw solid;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0.156vw 0.417vw;
  transition: all 150ms linear;
  background-size: contain;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.imgHolder:hover{
  scale: 1.2;
  rotate:unset;
}
.img1 {
  background-image: url(./assets/7.png);
  animation-name: subtleRotate1;
}

.img2 {
  background-image: url(./assets/5.png);
  animation-name: subtleRotate2;
}

.img3 {
  background-image: url(./assets/6.png);
  animation-name: subtleRotate3;
}

.img4 {
  background-image: url(./assets/4.png);
  animation-name: subtleRotate4;
}.r2deg{rotate: 2deg;}
.r5deg{rotate: 5deg;}
.r4degr{rotate: -4deg;}
.r3degr{rotate: -3deg;}